import React from "react"
import { Header, Footer, SEO, Slideshow } from "../components"
import "./program.scss"

export default ({ pageContext: { frontmatter: el, html } }) => {
  const imgTags = html.match(/<img(.[^>]*)>/g)
  html = html.replace(/<img(.[^>]*)>/g, "")

  const programPoster = [el.thumbnail, el.title, el.title]
  const images = [programPoster]
  if (imgTags)
    imgTags.map(el => {
      const src = el.match(/src\s*=\s*"(.+?)"/g)[0].slice(5, -1)
      const alt = el.match(/alt\s*=\s*"(.+?)"/g)[0].slice(5, -1)
      const title = el.match(/title\s*=\s*"(.+?)"/g)[0].slice(7, -1)
      images.push([src, alt, title])
    })

  return (
    <div id="program">
      <SEO title="Discrit" description="Art in Conversation" />
      <Header />
      <main>
        <Slideshow images={images} background={programPoster} />
        <div className="container">
          <div className="details">
            <div className="date-type">
              <h2>{el.date}</h2>
              <p>{el.type}</p>
            </div>
            <h1>{el.title}</h1>
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </main>
      <Footer />
    </div>
  )
}
